/* Exports:
 *   cities       the cities, their commodities, and their connected routes
 *   routes       segments of track between the cities
 *   commodities  what each city supplies
 * 
 * source: https://docs.google.com/spreadsheets/d/1r1ffSOFxTSjz-kltB9eLAaIVIo5z_CFEEDkP6zkNr0g/edit?usp=sharing
 */

/*
 * cities
 * 
 * key:           string - name of city
 * value: {
 *  id              string
 *  state           string
 *  country         string
 *  region          ["Eastern", "Central", "Western"]
 *  label           string (can be null) - prettier version of key, or null if the key is already pretty to humans
 *  latitude        number
 *  longitude       number
 *  large           boolean
 *  westCoast       boolean
 *  nearWestCoast   boolean
 *  nearEastCoast   boolean
 *  commodities     string[]
 *  routes          string[] - keys for routes Map
 * }
 */

export const cities = new Map([
  [ "Albany", { "id": "alb", "state": "NY", "country": "US", "region": "Eastern", "label": null, "latitude": 42.6512, "longitude": -73.755, "large": false, "westCoast": false, "nearWestCoast": false, "nearEastCoast": true, "commodities": [], "routes": ["Albany-Boston", "Albany-Montreal", "Albany-New York", "Albany-Syracuse"]} ],
  [ "Atlanta", { "id": "atl", "state": "GA", "country": "US", "region": "Eastern", "label": null, "latitude": 33.7491, "longitude": -84.3902, "large": true, "westCoast": false, "nearWestCoast": false, "nearEastCoast": true, "commodities": ["coal", "cotton", "textiles"], "routes": ["Atlanta-Birmingham", "Atlanta-Nashville", "Atlanta-Raleigh", "Atlanta-Savannah", "Atlanta-Tallahassee"]} ],
  [ "Billings", { "id": "bil", "state": "MT", "country": "US", "region": "Western", "label": null, "latitude": 45.7875, "longitude": -108.4961, "large": false, "westCoast": false, "nearWestCoast": false, "nearEastCoast": false, "commodities": ["copper", "lead"], "routes": ["Billings-Bismarck", "Billings-Butte"]} ],
  [ "Birmingham", { "id": "bir", "state": "AL", "country": "US", "region": "Eastern", "label": null, "latitude": 33.5207, "longitude": -86.8024, "large": false, "westCoast": false, "nearWestCoast": false, "nearEastCoast": false, "commodities": ["steel"], "routes": ["Atlanta-Birmingham", "Birmingham-Memphis", "Birmingham-Mobile", "Birmingham-Nashville"]} ],
  [ "Bismarck", { "id": "bis", "state": "ND", "country": "US", "region": "Western", "label": null, "latitude": 46.8083, "longitude": -100.7837, "large": false, "westCoast": false, "nearWestCoast": false, "nearEastCoast": false, "commodities": [], "routes": ["Billings-Bismarck", "Bismarck-Fargo", "Bismarck-Rapid City", "Bismarck-Regina"]} ],
  [ "Boise", { "id": "boi", "state": "ID", "country": "US", "region": "Western", "label": null, "latitude": 43.615, "longitude": -116.2044, "large": false, "westCoast": false, "nearWestCoast": true, "nearEastCoast": false, "commodities": ["copper"], "routes": ["Boise-Butte", "Boise-Portland OR", "Boise-Salt Lake City"]} ],
  [ "Boston", { "id": "bos", "state": "MA", "country": "US", "region": "Eastern", "label": null, "latitude": 42.3605, "longitude": -71.0596, "large": false, "westCoast": false, "nearWestCoast": false, "nearEastCoast": false, "commodities": ["imports", "machinery"], "routes": ["Albany-Boston", "Boston-New York", "Boston-Portland ME"]} ],
  [ "Buffalo", { "id": "buf", "state": "NY", "country": "US", "region": "Eastern", "label": null, "latitude": 42.9333, "longitude": -78.877, "large": false, "westCoast": false, "nearWestCoast": false, "nearEastCoast": false, "commodities": ["machinery"], "routes": ["Buffalo-Cleveland", "Buffalo-Syracuse", "Buffalo-Toronto"]} ],
  [ "Butte", { "id": "but", "state": "MT", "country": "US", "region": "Western", "label": null, "latitude": 45.9838, "longitude": -112.5007, "large": false, "westCoast": false, "nearWestCoast": false, "nearEastCoast": false, "commodities": ["coal", "lead", "sheep"], "routes": ["Billings-Butte", "Boise-Butte", "Butte-Spokane"]} ],
  [ "Calgary", { "id": "cal", "state": "AB", "country": "CA", "region": "Western", "label": null, "latitude": 51.0531, "longitude": -114.0626, "large": false, "westCoast": false, "nearWestCoast": false, "nearEastCoast": false, "commodities": ["coal", "copper", "lead"], "routes": ["Calgary-Edmonton", "Calgary-Regina", "Calgary-Spokane", "Calgary-Vancouver"]} ],
  [ "Charleston", { "id": "cha", "state": "SC", "country": "US", "region": "Eastern", "label": null, "latitude": 32.7876, "longitude": -79.9403, "large": false, "westCoast": false, "nearWestCoast": false, "nearEastCoast": false, "commodities": ["cotton", "tobacco"], "routes": ["Charleston-Raleigh", "Charleston-Savannah"]} ],
  [ "Chicago", { "id": "chi", "state": "IL", "country": "US", "region": "Central", "label": null, "latitude": 41.8756, "longitude": -87.6244, "large": true, "westCoast": false, "nearWestCoast": false, "nearEastCoast": false, "commodities": ["coal", "machinery"], "routes": ["Chicago-Des Moines", "Chicago-Indianapolis", "Chicago-Milwaukee", "Chicago-Saint Louis", "Chicago-Toledo"]} ],
  [ "Cincinnati", { "id": "cin", "state": "OH", "country": "US", "region": "Central", "label": null, "latitude": 39.1015, "longitude": -84.5125, "large": false, "westCoast": false, "nearWestCoast": false, "nearEastCoast": false, "commodities": ["coal", "wheat"], "routes": ["Cincinnati-Cleveland", "Cincinnati-Indianapolis", "Cincinnati-Louisville", "Cincinnati-Pittsburgh", "Cincinnati-Toledo"]} ],
  [ "Cleveland", { "id": "cle", "state": "OH", "country": "US", "region": "Central", "label": null, "latitude": 41.5052, "longitude": -81.6934, "large": false, "westCoast": false, "nearWestCoast": false, "nearEastCoast": true, "commodities": ["salt"], "routes": ["Buffalo-Cleveland", "Cincinnati-Cleveland", "Cleveland-New York", "Cleveland-Pittsburgh", "Cleveland-Toledo"]} ],
  [ "Dallas", { "id": "dal", "state": "TX", "country": "US", "region": "Central", "label": null, "latitude": 32.7763, "longitude": -96.7969, "large": false, "westCoast": false, "nearWestCoast": false, "nearEastCoast": false, "commodities": ["oil"], "routes": ["Dallas-El Paso", "Dallas-Houston", "Dallas-Memphis", "Dallas-Oklahoma City", "Dallas-San Antonio"]} ],
  [ "Denver", { "id": "den", "state": "CO", "country": "US", "region": "Western", "label": null, "latitude": 39.7348, "longitude": -104.9653, "large": false, "westCoast": false, "nearWestCoast": false, "nearEastCoast": false, "commodities": ["sheep"], "routes": ["Denver-Dodge City", "Denver-Omaha", "Denver-Salt Lake City"]} ],
  [ "Des Moines", { "id": "dm", "state": "IA", "country": "US", "region": "Central", "label": null, "latitude": 41.5911, "longitude": -93.6037, "large": false, "westCoast": false, "nearWestCoast": false, "nearEastCoast": false, "commodities": ["corn", "wheat"], "routes": ["Chicago-Des Moines", "Des Moines-Kansas City", "Des Moines-Milwaukee", "Des Moines-Minneapolis", "Des Moines-Omaha", "Des Moines-Saint Louis", "Des Moines-Sioux City"]} ],
  [ "Detroit", { "id": "det", "state": "MI", "country": "US", "region": "Central", "label": null, "latitude": 42.3487, "longitude": -83.0567, "large": false, "westCoast": false, "nearWestCoast": false, "nearEastCoast": false, "commodities": ["cars"], "routes": ["Detroit-Grand Rapids", "Detroit-Toledo", "Detroit-Toronto"]} ],
  [ "Dodge City", { "id": "dc", "state": "KS", "country": "US", "region": "Central", "label": null, "latitude": 37.7528, "longitude": -100.0171, "large": false, "westCoast": false, "nearWestCoast": false, "nearEastCoast": false, "commodities": ["cattle", "wheat"], "routes": ["Denver-Dodge City", "Dodge City-Kansas City", "Dodge City-Oklahoma City", "Dodge City-Santa Fe"]} ],
  [ "Duluth", { "id": "dul", "state": "MN", "country": "US", "region": "Central", "label": null, "latitude": 46.7729, "longitude": -92.1251, "large": false, "westCoast": false, "nearWestCoast": false, "nearEastCoast": false, "commodities": ["iron ore"], "routes": ["Duluth-Fargo", "Duluth-Milwaukee", "Duluth-Minneapolis", "Duluth-Winnipeg"]} ],
  [ "Edmonton", { "id": "edm", "state": "AB", "country": "CA", "region": "Western", "label": null, "latitude": 53.5344, "longitude": -113.5065, "large": false, "westCoast": false, "nearWestCoast": false, "nearEastCoast": false, "commodities": ["oil"], "routes": ["Calgary-Edmonton", "Edmonton-Saskatoon"]} ],
  [ "El Paso", { "id": "ep", "state": "TX", "country": "US", "region": "Western", "label": null, "latitude": 31.8064, "longitude": -106.2221, "large": false, "westCoast": false, "nearWestCoast": false, "nearEastCoast": false, "commodities": ["copper"], "routes": ["Dallas-El Paso", "El Paso-Oklahoma City", "El Paso-Phoenix", "El Paso-San Antonio", "El Paso-Santa Fe"]} ],
  [ "Fargo", { "id": "far", "state": "ND", "country": "US", "region": "Central", "label": null, "latitude": 46.8772, "longitude": -96.7898, "large": false, "westCoast": false, "nearWestCoast": false, "nearEastCoast": false, "commodities": ["pork"], "routes": ["Bismarck-Fargo", "Duluth-Fargo", "Fargo-Minneapolis", "Fargo-Sioux City", "Fargo-Winnipeg"]} ],
  [ "Flagstaff", { "id": "fla", "state": "AZ", "country": "US", "region": "Western", "label": null, "latitude": 35.1995, "longitude": -111.6514, "large": false, "westCoast": false, "nearWestCoast": true, "nearEastCoast": false, "commodities": ["copper"], "routes": ["Flagstaff-Los Angeles", "Flagstaff-Phoenix", "Flagstaff-Santa Fe"]} ],
  [ "Grand Rapids", { "id": "gr", "state": "MI", "country": "US", "region": "Central", "label": null, "latitude": 42.9632, "longitude": -85.6679, "large": false, "westCoast": false, "nearWestCoast": false, "nearEastCoast": false, "commodities": ["iron ore"], "routes": ["Detroit-Grand Rapids", "Grand Rapids-Toledo"]} ],
  [ "Houston", { "id": "hou", "state": "TX", "country": "US", "region": "Central", "label": null, "latitude": 29.7589, "longitude": -95.3677, "large": false, "westCoast": false, "nearWestCoast": false, "nearEastCoast": false, "commodities": ["oil", "rice"], "routes": ["Dallas-Houston", "Houston-Memphis", "Houston-New Orleans", "Houston-San Antonio"]} ],
  [ "Indianapolis", { "id": "ind", "state": "IN", "country": "US", "region": "Central", "label": null, "latitude": 39.7683, "longitude": -86.1584, "large": false, "westCoast": false, "nearWestCoast": false, "nearEastCoast": false, "commodities": ["corn", "pork"], "routes": ["Chicago-Indianapolis", "Cincinnati-Indianapolis", "Indianapolis-Louisville", "Indianapolis-Saint Louis", "Indianapolis-Toledo"]} ],
  [ "Jacksonville", { "id": "jac", "state": "FL", "country": "US", "region": "Eastern", "label": null, "latitude": 30.3322, "longitude": -81.6557, "large": false, "westCoast": false, "nearWestCoast": false, "nearEastCoast": false, "commodities": [], "routes": ["Jacksonville-Miami", "Jacksonville-Savannah", "Jacksonville-Tallahassee", "Jacksonville-Tampa"]} ],
  [ "Kansas City", { "id": "kc", "state": "KS", "country": "US", "region": "Central", "label": null, "latitude": 39.1147, "longitude": -94.7495, "large": true, "westCoast": false, "nearWestCoast": false, "nearEastCoast": false, "commodities": ["cattle", "salt", "wheat"], "routes": ["Des Moines-Kansas City", "Dodge City-Kansas City", "Kansas City-Oklahoma City", "Kansas City-Omaha", "Kansas City-Saint Louis"]} ],
  [ "Los Angeles", { "id": "la", "state": "CA", "country": "US", "region": "Western", "label": null, "latitude": 34.0544, "longitude": -118.2439, "large": true, "westCoast": true, "nearWestCoast": false, "nearEastCoast": false, "commodities": ["fruit", "imports", "wine"], "routes": ["Flagstaff-Los Angeles", "Los Angeles-San Diego", "Los Angeles-San Francisco"]} ],
  [ "Louisville", { "id": "lou", "state": "KY", "country": "US", "region": "Central", "label": null, "latitude": 38.2542, "longitude": -85.7594, "large": false, "westCoast": false, "nearWestCoast": false, "nearEastCoast": false, "commodities": [], "routes": ["Cincinnati-Louisville", "Indianapolis-Louisville", "Louisville-Nashville", "Louisville-Saint Louis"]} ],
  [ "Medford", { "id": "med", "state": "OR", "country": "US", "region": "Western", "label": null, "latitude": 42.3264, "longitude": -122.8719, "large": false, "westCoast": false, "nearWestCoast": false, "nearEastCoast": false, "commodities": [], "routes": ["Medford-Redding", "Medford-Salem"]} ],
  [ "Memphis", { "id": "mem", "state": "TN", "country": "US", "region": "Central", "label": null, "latitude": 35.149, "longitude": -90.0516, "large": false, "westCoast": false, "nearWestCoast": false, "nearEastCoast": false, "commodities": ["bauxite", "paper"], "routes": ["Birmingham-Memphis", "Dallas-Memphis", "Houston-Memphis", "Memphis-Nashville", "Memphis-New Orleans", "Memphis-Oklahoma City", "Memphis-Saint Louis"]} ],
  [ "Miami", { "id": "mia", "state": "FL", "country": "US", "region": "Eastern", "label": null, "latitude": 25.7743, "longitude": -80.1937, "large": false, "westCoast": false, "nearWestCoast": false, "nearEastCoast": false, "commodities": ["cattle"], "routes": ["Jacksonville-Miami", "Miami-Tampa"]} ],
  [ "Milwaukee", { "id": "mil", "state": "WI", "country": "US", "region": "Central", "label": null, "latitude": 43.0115, "longitude": -87.9735, "large": false, "westCoast": false, "nearWestCoast": false, "nearEastCoast": false, "commodities": ["beer", "machinery"], "routes": ["Chicago-Milwaukee", "Des Moines-Milwaukee", "Duluth-Milwaukee", "Milwaukee-Minneapolis"]} ],
  [ "Minneapolis", { "id": "min", "state": "MN", "country": "US", "region": "Central", "label": null, "latitude": 44.9773, "longitude": -93.2655, "large": false, "westCoast": false, "nearWestCoast": false, "nearEastCoast": false, "commodities": ["corn", "iron ore", "pork"], "routes": ["Des Moines-Minneapolis", "Duluth-Minneapolis", "Fargo-Minneapolis", "Milwaukee-Minneapolis", "Minneapolis-Sioux City"]} ],
  [ "Mobile", { "id": "mob", "state": "AL", "country": "US", "region": "Central", "label": null, "latitude": 30.6488, "longitude": -88.1946, "large": false, "westCoast": false, "nearWestCoast": false, "nearEastCoast": false, "commodities": ["rice"], "routes": ["Birmingham-Mobile", "Mobile-New Orleans", "Mobile-Tallahassee"]} ],
  [ "Montreal", { "id": "mon", "state": "QC", "country": "CA", "region": "Eastern", "label": null, "latitude": 45.5088, "longitude": -73.554, "large": false, "westCoast": false, "nearWestCoast": false, "nearEastCoast": false, "commodities": ["imports"], "routes": ["Albany-Montreal", "Montreal-Ottawa", "Montreal-Quebec City"]} ],
  [ "Nashville", { "id": "nas", "state": "TN", "country": "US", "region": "Central", "label": null, "latitude": 36.1622, "longitude": -86.7744, "large": false, "westCoast": false, "nearWestCoast": false, "nearEastCoast": false, "commodities": ["coal"], "routes": ["Atlanta-Nashville", "Birmingham-Nashville", "Louisville-Nashville", "Memphis-Nashville", "Nashville-Saint Louis"]} ],
  [ "New Orleans", { "id": "no", "state": "LA", "country": "US", "region": "Central", "label": null, "latitude": 29.9499, "longitude": -90.0701, "large": false, "westCoast": false, "nearWestCoast": false, "nearEastCoast": false, "commodities": ["fish", "rice", "salt"], "routes": ["Houston-New Orleans", "Memphis-New Orleans", "Mobile-New Orleans"]} ],
  [ "New York", { "id": "ny", "state": "NY", "country": "US", "region": "Eastern", "label": null, "latitude": 40.7648, "longitude": -73.9808, "large": true, "westCoast": false, "nearWestCoast": false, "nearEastCoast": false, "commodities": ["imports", "salt", "tourists"], "routes": ["Albany-New York", "Boston-New York", "Cleveland-New York", "New York-Philadelphia", "New York-Syracuse"]} ],
  [ "Norfolk", { "id": "nor", "state": "VA", "country": "US", "region": "Eastern", "label": null, "latitude": 36.8465, "longitude": -76.2916, "large": false, "westCoast": false, "nearWestCoast": false, "nearEastCoast": false, "commodities": ["tobacco"], "routes": ["Norfolk-Raleigh", "Norfolk-Richmond"]} ],
  [ "Oklahoma City", { "id": "oc", "state": "OK", "country": "US", "region": "Central", "label": null, "latitude": 35.473, "longitude": -97.5171, "large": false, "westCoast": false, "nearWestCoast": false, "nearEastCoast": false, "commodities": ["oil"], "routes": ["Dallas-Oklahoma City", "Dodge City-Oklahoma City", "El Paso-Oklahoma City", "Kansas City-Oklahoma City", "Memphis-Oklahoma City"]} ],
  [ "Omaha", { "id": "oma", "state": "NE", "country": "US", "region": "Central", "label": null, "latitude": 41.2587, "longitude": -95.9379, "large": false, "westCoast": false, "nearWestCoast": false, "nearEastCoast": false, "commodities": ["oats", "wheat"], "routes": ["Denver-Omaha", "Des Moines-Omaha", "Kansas City-Omaha", "Omaha-Sioux City"]} ],
  [ "Ottawa", { "id": "ott", "state": "ON", "country": "CA", "region": "Eastern", "label": null, "latitude": 45.421, "longitude": -75.69, "large": false, "westCoast": false, "nearWestCoast": false, "nearEastCoast": false, "commodities": ["wood"], "routes": ["Montreal-Ottawa", "Ottawa-Sudbury", "Ottawa-Syracuse", "Ottawa-Toronto"]} ],
  [ "Philadelphia", { "id": "phi", "state": "PA", "country": "US", "region": "Eastern", "label": null, "latitude": 40.0115, "longitude": -75.1327, "large": false, "westCoast": false, "nearWestCoast": false, "nearEastCoast": false, "commodities": ["imports", "salt", "tourists"], "routes": ["New York-Philadelphia", "Philadelphia-Pittsburgh", "Philadelphia-Washington"]} ],
  [ "Phoenix", { "id": "pho", "state": "AZ", "country": "US", "region": "Western", "label": null, "latitude": 33.4486, "longitude": -112.0773, "large": false, "westCoast": false, "nearWestCoast": true, "nearEastCoast": false, "commodities": ["copper"], "routes": ["El Paso-Phoenix", "Flagstaff-Phoenix", "Phoenix-San Diego"]} ],
  [ "Pittsburgh", { "id": "pit", "state": "PA", "country": "US", "region": "Eastern", "label": null, "latitude": 40.4417, "longitude": -79.9901, "large": false, "westCoast": false, "nearWestCoast": false, "nearEastCoast": true, "commodities": ["coal", "steel"], "routes": ["Cincinnati-Pittsburgh", "Cleveland-Pittsburgh", "Philadelphia-Pittsburgh", "Pittsburgh-Washington"]} ],
  [ "Portland ME", { "id": "porm", "state": "ME", "country": "US", "region": "Eastern", "label": "Portland, Maine", "latitude": 43.661, "longitude": -70.2549, "large": false, "westCoast": false, "nearWestCoast": false, "nearEastCoast": false, "commodities": ["aluminum", "wood"], "routes": ["Boston-Portland ME", "Portland ME-Quebec City", "Portland ME-Saint John"]} ],
  [ "Portland OR", { "id": "poro", "state": "OR", "country": "US", "region": "Western", "label": "Portland, Ore.", "latitude": 45.5202, "longitude": -122.6742, "large": false, "westCoast": true, "nearWestCoast": false, "nearEastCoast": false, "commodities": ["wood"], "routes": ["Boise-Portland OR", "Portland OR-Salem", "Portland OR-Seattle", "Portland OR-Spokane"]} ],
  [ "Quebec City", { "id": "que", "state": "QC", "country": "CA", "region": "Eastern", "label": null, "latitude": 46.8257, "longitude": -71.2349, "large": false, "westCoast": false, "nearWestCoast": false, "nearEastCoast": true, "commodities": ["imports", "wood"], "routes": ["Montreal-Quebec City", "Portland ME-Quebec City", "Quebec City-Saint John"]} ],
  [ "Raleigh", { "id": "ral", "state": "NC", "country": "US", "region": "Eastern", "label": null, "latitude": 35.7804, "longitude": -78.6391, "large": false, "westCoast": false, "nearWestCoast": false, "nearEastCoast": false, "commodities": ["textiles", "tobacco"], "routes": ["Atlanta-Raleigh", "Charleston-Raleigh", "Norfolk-Raleigh", "Raleigh-Richmond"]} ],
  [ "Rapid City", { "id": "rap", "state": "SD", "country": "US", "region": "Western", "label": null, "latitude": 44.0812, "longitude": -103.228, "large": false, "westCoast": false, "nearWestCoast": false, "nearEastCoast": false, "commodities": ["cattle"], "routes": ["Bismarck-Rapid City", "Rapid City-Sioux City"]} ],
  [ "Redding", { "id": "red", "state": "CA", "country": "US", "region": "Western", "label": null, "latitude": 40.5864, "longitude": -122.3917, "large": false, "westCoast": true, "nearWestCoast": false, "nearEastCoast": false, "commodities": ["iron ore"], "routes": ["Medford-Redding", "Redding-Reno", "Redding-San Francisco"]} ],
  [ "Regina", { "id": "reg", "state": "SK", "country": "CA", "region": "Western", "label": null, "latitude": 50.4481, "longitude": -104.6158, "large": false, "westCoast": false, "nearWestCoast": false, "nearEastCoast": false, "commodities": ["lead", "nickel", "oats"], "routes": ["Bismarck-Regina", "Calgary-Regina", "Regina-Saskatoon", "Regina-Winnipeg"]} ],
  [ "Reno", { "id": "ren", "state": "NV", "country": "US", "region": "Western", "label": null, "latitude": 39.5293, "longitude": -119.8137, "large": false, "westCoast": false, "nearWestCoast": true, "nearEastCoast": false, "commodities": ["precious metals"], "routes": ["Redding-Reno", "Reno-Salt Lake City", "Reno-San Francisco"]} ],
  [ "Richmond", { "id": "ric", "state": "VA", "country": "US", "region": "Eastern", "label": null, "latitude": 37.5385, "longitude": -77.4343, "large": false, "westCoast": false, "nearWestCoast": false, "nearEastCoast": false, "commodities": ["coal"], "routes": ["Norfolk-Richmond", "Raleigh-Richmond", "Richmond-Washington"]} ],
  [ "Saint John", { "id": "sj", "state": "NB", "country": "CA", "region": "Eastern", "label": null, "latitude": 45.278, "longitude": -66.0559, "large": false, "westCoast": false, "nearWestCoast": false, "nearEastCoast": false, "commodities": ["coal"], "routes": ["Portland ME-Saint John", "Quebec City-Saint John"]} ],
  [ "Saint Louis", { "id": "sl", "state": "MO", "country": "US", "region": "Central", "label": null, "latitude": 38.63, "longitude": -90.19, "large": false, "westCoast": false, "nearWestCoast": false, "nearEastCoast": false, "commodities": ["beer", "paper"], "routes": ["Chicago-Saint Louis", "Des Moines-Saint Louis", "Indianapolis-Saint Louis", "Kansas City-Saint Louis", "Louisville-Saint Louis", "Memphis-Saint Louis", "Nashville-Saint Louis"]} ],
  [ "Salem", { "id": "sal", "state": "OR", "country": "US", "region": "Western", "label": null, "latitude": 44.9392, "longitude": -123.0331, "large": false, "westCoast": true, "nearWestCoast": false, "nearEastCoast": false, "commodities": ["wood"], "routes": ["Medford-Salem", "Portland OR-Salem"]} ],
  [ "Salt Lake City", { "id": "slc", "state": "UT", "country": "US", "region": "Western", "label": null, "latitude": 40.767, "longitude": -111.8904, "large": false, "westCoast": false, "nearWestCoast": false, "nearEastCoast": false, "commodities": ["iron ore", "salt", "sheep"], "routes": ["Boise-Salt Lake City", "Denver-Salt Lake City", "Reno-Salt Lake City"]} ],
  [ "San Antonio", { "id": "sa", "state": "TX", "country": "US", "region": "Central", "label": null, "latitude": 29.4246, "longitude": -98.4951, "large": false, "westCoast": false, "nearWestCoast": false, "nearEastCoast": false, "commodities": ["salt"], "routes": ["Dallas-San Antonio", "El Paso-San Antonio", "Houston-San Antonio"]} ],
  [ "San Diego", { "id": "sd", "state": "CA", "country": "US", "region": "Western", "label": null, "latitude": 32.7174, "longitude": -117.1628, "large": false, "westCoast": true, "nearWestCoast": false, "nearEastCoast": false, "commodities": ["fruit", "salt"], "routes": ["Los Angeles-San Diego", "Phoenix-San Diego"]} ],
  [ "San Francisco", { "id": "sfo", "state": "CA", "country": "US", "region": "Western", "label": null, "latitude": 37.7648, "longitude": -122.463, "large": true, "westCoast": true, "nearWestCoast": false, "nearEastCoast": false, "commodities": ["imports", "precious metals", "wine"], "routes": ["Los Angeles-San Francisco", "Redding-San Francisco", "Reno-San Francisco"]} ],
  [ "Santa Fe", { "id": "sfe", "state": "NM", "country": "US", "region": "Western", "label": null, "latitude": 35.687, "longitude": -105.9378, "large": false, "westCoast": false, "nearWestCoast": false, "nearEastCoast": false, "commodities": ["potash"], "routes": ["Dodge City-Santa Fe", "El Paso-Santa Fe", "Flagstaff-Santa Fe"]} ],
  [ "Saskatoon", { "id": "sas", "state": "SK", "country": "CA", "region": "Western", "label": null, "latitude": 52.1304, "longitude": -106.6605, "large": false, "westCoast": false, "nearWestCoast": false, "nearEastCoast": false, "commodities": ["potash"], "routes": ["Edmonton-Saskatoon", "Regina-Saskatoon"]} ],
  [ "Savannah", { "id": "sav", "state": "GA", "country": "US", "region": "Eastern", "label": null, "latitude": 32.0835, "longitude": -81.0998, "large": false, "westCoast": false, "nearWestCoast": false, "nearEastCoast": false, "commodities": ["textiles"], "routes": ["Atlanta-Savannah", "Charleston-Savannah", "Jacksonville-Savannah"]} ],
  [ "Seattle", { "id": "sea", "state": "WA", "country": "US", "region": "Western", "label": null, "latitude": 47.6038, "longitude": -122.3301, "large": true, "westCoast": true, "nearWestCoast": false, "nearEastCoast": false, "commodities": ["fish", "wood"], "routes": ["Portland OR-Seattle", "Seattle-Spokane", "Seattle-Vancouver"]} ],
  [ "Sioux City", { "id": "sc", "state": "IA", "country": "US", "region": "Central", "label": null, "latitude": 42.4967, "longitude": -96.4059, "large": false, "westCoast": false, "nearWestCoast": false, "nearEastCoast": false, "commodities": ["oats", "pork"], "routes": ["Des Moines-Sioux City", "Fargo-Sioux City", "Minneapolis-Sioux City", "Omaha-Sioux City", "Rapid City-Sioux City"]} ],
  [ "Spokane", { "id": "spo", "state": "WA", "country": "US", "region": "Western", "label": null, "latitude": 47.6589, "longitude": -117.4247, "large": false, "westCoast": false, "nearWestCoast": true, "nearEastCoast": false, "commodities": ["wine"], "routes": ["Butte-Spokane", "Calgary-Spokane", "Portland OR-Spokane", "Seattle-Spokane"]} ],
  [ "Sudbury", { "id": "sud", "state": "ON", "country": "CA", "region": "Central", "label": null, "latitude": 47.77, "longitude": -82.08, "large": false, "westCoast": false, "nearWestCoast": false, "nearEastCoast": false, "commodities": ["nickel"], "routes": ["Ottawa-Sudbury", "Sudbury-Thunder Bay", "Sudbury-Toronto"]} ],
  [ "Syracuse", { "id": "syr", "state": "NY", "country": "US", "region": "Eastern", "label": null, "latitude": 43.0481, "longitude": -76.1474, "large": false, "westCoast": false, "nearWestCoast": false, "nearEastCoast": false, "commodities": ["machinery"], "routes": ["Albany-Syracuse", "Buffalo-Syracuse", "New York-Syracuse", "Ottawa-Syracuse"]} ],
  [ "Tallahassee", { "id": "tal", "state": "FL", "country": "US", "region": "Eastern", "label": null, "latitude": 30.4381, "longitude": -84.2809, "large": false, "westCoast": false, "nearWestCoast": false, "nearEastCoast": true, "commodities": ["textiles"], "routes": ["Atlanta-Tallahassee", "Jacksonville-Tallahassee", "Mobile-Tallahassee", "Tallahassee-Tampa"]} ],
  [ "Tampa", { "id": "tam", "state": "FL", "country": "US", "region": "Eastern", "label": null, "latitude": 27.9478, "longitude": -82.4584, "large": false, "westCoast": false, "nearWestCoast": false, "nearEastCoast": false, "commodities": ["fruit"], "routes": ["Jacksonville-Tampa", "Miami-Tampa", "Tallahassee-Tampa"]} ],
  [ "Thunder Bay", { "id": "tb", "state": "ON", "country": "CA", "region": "Central", "label": null, "latitude": 48.4062, "longitude": -89.2591, "large": false, "westCoast": false, "nearWestCoast": false, "nearEastCoast": false, "commodities": ["cattle", "iron ore", "oats"], "routes": ["Sudbury-Thunder Bay", "Thunder Bay-Winnipeg"]} ],
  [ "Toledo", { "id": "tol", "state": "OH", "country": "US", "region": "Central", "label": null, "latitude": 41.6787, "longitude": -83.5127, "large": false, "westCoast": false, "nearWestCoast": false, "nearEastCoast": false, "commodities": ["machinery"], "routes": ["Chicago-Toledo", "Cincinnati-Toledo", "Cleveland-Toledo", "Detroit-Toledo", "Grand Rapids-Toledo", "Indianapolis-Toledo"]} ],
  [ "Toronto", { "id": "tor", "state": "ON", "country": "CA", "region": "Central", "label": null, "latitude": 43.6529, "longitude": -79.3849, "large": false, "westCoast": false, "nearWestCoast": false, "nearEastCoast": false, "commodities": ["cars"], "routes": ["Buffalo-Toronto", "Detroit-Toronto", "Ottawa-Toronto", "Sudbury-Toronto"]} ],
  [ "Vancouver", { "id": "van", "state": "BC", "country": "CA", "region": "Western", "label": null, "latitude": 49.2609, "longitude": -123.1139, "large": false, "westCoast": true, "nearWestCoast": false, "nearEastCoast": false, "commodities": ["fish", "precious metals", "wood"], "routes": ["Calgary-Vancouver", "Seattle-Vancouver"]} ],
  [ "Washington", { "id": "was", "state": "DC", "country": "US", "region": "Eastern", "label": null, "latitude": 38.895, "longitude": -77.0366, "large": false, "westCoast": false, "nearWestCoast": false, "nearEastCoast": false, "commodities": [], "routes": ["Philadelphia-Washington", "Pittsburgh-Washington", "Richmond-Washington"]} ],
  [ "Winnipeg", { "id": "win", "state": "MB", "country": "CA", "region": "Central", "label": null, "latitude": 49.8833, "longitude": -97.1667, "large": false, "westCoast": false, "nearWestCoast": false, "nearEastCoast": false, "commodities": ["cattle", "wheat"], "routes": ["Duluth-Winnipeg", "Fargo-Winnipeg", "Regina-Winnipeg", "Thunder Bay-Winnipeg"]} ]
]);

/*
 * routes
 * 
 * key:           string - keys of connected cities, dash-separated and in alphabetical order
 * value: {
 *  length          number - integer in the range [1, 5]
 *  mountainous     boolean
 *  cities          string[] - keys of connected cities
 * }
 */

export const routes = new Map([
  [ "Albany-Boston", { "length": 1, "mountainous": false, cities: ["Albany", "Boston"] } ],
  [ "Albany-Montreal", { "length": 2, "mountainous": false, cities: ["Albany", "Montreal"] } ],
  [ "Albany-New York", { "length": 1, "mountainous": false, cities: ["Albany", "New York"] } ],
  [ "Albany-Syracuse", { "length": 1, "mountainous": false, cities: ["Albany", "Syracuse"] } ],
  [ "Atlanta-Birmingham", { "length": 1, "mountainous": false, cities: ["Atlanta", "Birmingham"] } ],
  [ "Atlanta-Nashville", { "length": 2, "mountainous": true, cities: ["Atlanta", "Nashville"] } ],
  [ "Atlanta-Raleigh", { "length": 4, "mountainous": false, cities: ["Atlanta", "Raleigh"] } ],
  [ "Atlanta-Savannah", { "length": 2, "mountainous": false, cities: ["Atlanta", "Savannah"] } ],
  [ "Atlanta-Tallahassee", { "length": 2, "mountainous": false, cities: ["Atlanta", "Tallahassee"] } ],
  [ "Billings-Bismarck", { "length": 4, "mountainous": false, cities: ["Billings", "Bismarck"] } ],
  [ "Billings-Butte", { "length": 2, "mountainous": true, cities: ["Billings", "Butte"] } ],
  [ "Birmingham-Memphis", { "length": 2, "mountainous": false, cities: ["Birmingham", "Memphis"] } ],
  [ "Birmingham-Mobile", { "length": 2, "mountainous": false, cities: ["Birmingham", "Mobile"] } ],
  [ "Birmingham-Nashville", { "length": 2, "mountainous": false, cities: ["Birmingham", "Nashville"] } ],
  [ "Bismarck-Fargo", { "length": 2, "mountainous": false, cities: ["Bismarck", "Fargo"] } ],
  [ "Bismarck-Rapid City", { "length": 2, "mountainous": false, cities: ["Bismarck", "Rapid City"] } ],
  [ "Bismarck-Regina", { "length": 3, "mountainous": false, cities: ["Bismarck", "Regina"] } ],
  [ "Boise-Butte", { "length": 2, "mountainous": true, cities: ["Boise", "Butte"] } ],
  [ "Boise-Portland OR", { "length": 3, "mountainous": true, cities: ["Boise", "Portland OR"] } ],
  [ "Boise-Salt Lake City", { "length": 3, "mountainous": true, cities: ["Boise", "Salt Lake City"] } ],
  [ "Boston-New York", { "length": 2, "mountainous": false, cities: ["Boston", "New York"] } ],
  [ "Boston-Portland ME", { "length": 1, "mountainous": false, cities: ["Boston", "Portland ME"] } ],
  [ "Buffalo-Cleveland", { "length": 2, "mountainous": false, cities: ["Buffalo", "Cleveland"] } ],
  [ "Buffalo-Syracuse", { "length": 1, "mountainous": false, cities: ["Buffalo", "Syracuse"] } ],
  [ "Buffalo-Toronto", { "length": 1, "mountainous": false, cities: ["Buffalo", "Toronto"] } ],
  [ "Butte-Spokane", { "length": 3, "mountainous": true, cities: ["Butte", "Spokane"] } ],
  [ "Calgary-Edmonton", { "length": 2, "mountainous": false, cities: ["Calgary", "Edmonton"] } ],
  [ "Calgary-Regina", { "length": 4, "mountainous": false, cities: ["Calgary", "Regina"] } ],
  [ "Calgary-Spokane", { "length": 3, "mountainous": true, cities: ["Calgary", "Spokane"] } ],
  [ "Calgary-Vancouver", { "length": 4, "mountainous": true, cities: ["Calgary", "Vancouver"] } ],
  [ "Charleston-Raleigh", { "length": 2, "mountainous": false, cities: ["Charleston", "Raleigh"] } ],
  [ "Charleston-Savannah", { "length": 1, "mountainous": false, cities: ["Charleston", "Savannah"] } ],
  [ "Chicago-Des Moines", { "length": 3, "mountainous": false, cities: ["Chicago", "Des Moines"] } ],
  [ "Chicago-Indianapolis", { "length": 1, "mountainous": false, cities: ["Chicago", "Indianapolis"] } ],
  [ "Chicago-Milwaukee", { "length": 1, "mountainous": false, cities: ["Chicago", "Milwaukee"] } ],
  [ "Chicago-Saint Louis", { "length": 3, "mountainous": false, cities: ["Chicago", "Saint Louis"] } ],
  [ "Chicago-Toledo", { "length": 2, "mountainous": false, cities: ["Chicago", "Toledo"] } ],
  [ "Cincinnati-Cleveland", { "length": 2, "mountainous": false, cities: ["Cincinnati", "Cleveland"] } ],
  [ "Cincinnati-Indianapolis", { "length": 2, "mountainous": false, cities: ["Cincinnati", "Indianapolis"] } ],
  [ "Cincinnati-Louisville", { "length": 1, "mountainous": false, cities: ["Cincinnati", "Louisville"] } ],
  [ "Cincinnati-Pittsburgh", { "length": 2, "mountainous": false, cities: ["Cincinnati", "Pittsburgh"] } ],
  [ "Cincinnati-Toledo", { "length": 2, "mountainous": false, cities: ["Cincinnati", "Toledo"] } ],
  [ "Cleveland-New York", { "length": 4, "mountainous": true, cities: ["Cleveland", "New York"] } ],
  [ "Cleveland-Pittsburgh", { "length": 1, "mountainous": false, cities: ["Cleveland", "Pittsburgh"] } ],
  [ "Cleveland-Toledo", { "length": 1, "mountainous": false, cities: ["Cleveland", "Toledo"] } ],
  [ "Dallas-El Paso", { "length": 5, "mountainous": false, cities: ["Dallas", "El Paso"] } ],
  [ "Dallas-Houston", { "length": 2, "mountainous": false, cities: ["Dallas", "Houston"] } ],
  [ "Dallas-Memphis", { "length": 4, "mountainous": false, cities: ["Dallas", "Memphis"] } ],
  [ "Dallas-Oklahoma City", { "length": 2, "mountainous": false, cities: ["Dallas", "Oklahoma City"] } ],
  [ "Dallas-San Antonio", { "length": 2, "mountainous": false, cities: ["Dallas", "San Antonio"] } ],
  [ "Denver-Dodge City", { "length": 3, "mountainous": false, cities: ["Denver", "Dodge City"] } ],
  [ "Denver-Omaha", { "length": 5, "mountainous": false, cities: ["Denver", "Omaha"] } ],
  [ "Denver-Salt Lake City", { "length": 4, "mountainous": true, cities: ["Denver", "Salt Lake City"] } ],
  [ "Des Moines-Kansas City", { "length": 2, "mountainous": false, cities: ["Des Moines", "Kansas City"] } ],
  [ "Des Moines-Milwaukee", { "length": 3, "mountainous": false, cities: ["Des Moines", "Milwaukee"] } ],
  [ "Des Moines-Minneapolis", { "length": 2, "mountainous": false, cities: ["Des Moines", "Minneapolis"] } ],
  [ "Des Moines-Omaha", { "length": 1, "mountainous": false, cities: ["Des Moines", "Omaha"] } ],
  [ "Des Moines-Saint Louis", { "length": 3, "mountainous": false, cities: ["Des Moines", "Saint Louis"] } ],
  [ "Des Moines-Sioux City", { "length": 1, "mountainous": false, cities: ["Des Moines", "Sioux City"] } ],
  [ "Detroit-Grand Rapids", { "length": 1, "mountainous": false, cities: ["Detroit", "Grand Rapids"] } ],
  [ "Detroit-Toledo", { "length": 1, "mountainous": false, cities: ["Detroit", "Toledo"] } ],
  [ "Detroit-Toronto", { "length": 2, "mountainous": false, cities: ["Detroit", "Toronto"] } ],
  [ "Dodge City-Kansas City", { "length": 3, "mountainous": false, cities: ["Dodge City", "Kansas City"] } ],
  [ "Dodge City-Oklahoma City", { "length": 2, "mountainous": false, cities: ["Dodge City", "Oklahoma City"] } ],
  [ "Dodge City-Santa Fe", { "length": 4, "mountainous": false, cities: ["Dodge City", "Santa Fe"] } ],
  [ "Duluth-Fargo", { "length": 2, "mountainous": false, cities: ["Duluth", "Fargo"] } ],
  [ "Duluth-Milwaukee", { "length": 3, "mountainous": false, cities: ["Duluth", "Milwaukee"] } ],
  [ "Duluth-Minneapolis", { "length": 1, "mountainous": false, cities: ["Duluth", "Minneapolis"] } ],
  [ "Duluth-Winnipeg", { "length": 3, "mountainous": false, cities: ["Duluth", "Winnipeg"] } ],
  [ "Edmonton-Saskatoon", { "length": 3, "mountainous": false, cities: ["Edmonton", "Saskatoon"] } ],
  [ "El Paso-Oklahoma City", { "length": 5, "mountainous": false, cities: ["El Paso", "Oklahoma City"] } ],
  [ "El Paso-Phoenix", { "length": 3, "mountainous": true, cities: ["El Paso", "Phoenix"] } ],
  [ "El Paso-San Antonio", { "length": 5, "mountainous": false, cities: ["El Paso", "San Antonio"] } ],
  [ "El Paso-Santa Fe", { "length": 3, "mountainous": false, cities: ["El Paso", "Santa Fe"] } ],
  [ "Fargo-Minneapolis", { "length": 2, "mountainous": false, cities: ["Fargo", "Minneapolis"] } ],
  [ "Fargo-Sioux City", { "length": 3, "mountainous": false, cities: ["Fargo", "Sioux City"] } ],
  [ "Fargo-Winnipeg", { "length": 2, "mountainous": false, cities: ["Fargo", "Winnipeg"] } ],
  [ "Flagstaff-Los Angeles", { "length": 4, "mountainous": true, cities: ["Flagstaff", "Los Angeles"] } ],
  [ "Flagstaff-Phoenix", { "length": 1, "mountainous": true, cities: ["Flagstaff", "Phoenix"] } ],
  [ "Flagstaff-Santa Fe", { "length": 3, "mountainous": true, cities: ["Flagstaff", "Santa Fe"] } ],
  [ "Grand Rapids-Toledo", { "length": 1, "mountainous": false, cities: ["Grand Rapids", "Toledo"] } ],
  [ "Houston-Memphis", { "length": 5, "mountainous": false, cities: ["Houston", "Memphis"] } ],
  [ "Houston-New Orleans", { "length": 3, "mountainous": false, cities: ["Houston", "New Orleans"] } ],
  [ "Houston-San Antonio", { "length": 2, "mountainous": false, cities: ["Houston", "San Antonio"] } ],
  [ "Indianapolis-Louisville", { "length": 1, "mountainous": false, cities: ["Indianapolis", "Louisville"] } ],
  [ "Indianapolis-Saint Louis", { "length": 2, "mountainous": false, cities: ["Indianapolis", "Saint Louis"] } ],
  [ "Indianapolis-Toledo", { "length": 2, "mountainous": false, cities: ["Indianapolis", "Toledo"] } ],
  [ "Jacksonville-Miami", { "length": 3, "mountainous": false, cities: ["Jacksonville", "Miami"] } ],
  [ "Jacksonville-Savannah", { "length": 1, "mountainous": false, cities: ["Jacksonville", "Savannah"] } ],
  [ "Jacksonville-Tallahassee", { "length": 1, "mountainous": false, cities: ["Jacksonville", "Tallahassee"] } ],
  [ "Jacksonville-Tampa", { "length": 2, "mountainous": false, cities: ["Jacksonville", "Tampa"] } ],
  [ "Kansas City-Oklahoma City", { "length": 3, "mountainous": false, cities: ["Kansas City", "Oklahoma City"] } ],
  [ "Kansas City-Omaha", { "length": 2, "mountainous": false, cities: ["Kansas City", "Omaha"] } ],
  [ "Kansas City-Saint Louis", { "length": 2, "mountainous": false, cities: ["Kansas City", "Saint Louis"] } ],
  [ "Los Angeles-San Diego", { "length": 1, "mountainous": true, cities: ["Los Angeles", "San Diego"] } ],
  [ "Los Angeles-San Francisco", { "length": 4, "mountainous": true, cities: ["Los Angeles", "San Francisco"] } ],
  [ "Louisville-Nashville", { "length": 1, "mountainous": false, cities: ["Louisville", "Nashville"] } ],
  [ "Louisville-Saint Louis", { "length": 2, "mountainous": false, cities: ["Louisville", "Saint Louis"] } ],
  [ "Medford-Redding", { "length": 1, "mountainous": true, cities: ["Medford", "Redding"] } ],
  [ "Medford-Salem", { "length": 2, "mountainous": false, cities: ["Medford", "Salem"] } ],
  [ "Memphis-Nashville", { "length": 2, "mountainous": false, cities: ["Memphis", "Nashville"] } ],
  [ "Memphis-New Orleans", { "length": 4, "mountainous": false, cities: ["Memphis", "New Orleans"] } ],
  [ "Memphis-Oklahoma City", { "length": 4, "mountainous": false, cities: ["Memphis", "Oklahoma City"] } ],
  [ "Memphis-Saint Louis", { "length": 2, "mountainous": false, cities: ["Memphis", "Saint Louis"] } ],
  [ "Miami-Tampa", { "length": 2, "mountainous": false, cities: ["Miami", "Tampa"] } ],
  [ "Milwaukee-Minneapolis", { "length": 3, "mountainous": false, cities: ["Milwaukee", "Minneapolis"] } ],
  [ "Minneapolis-Sioux City", { "length": 2, "mountainous": false, cities: ["Minneapolis", "Sioux City"] } ],
  [ "Mobile-New Orleans", { "length": 1, "mountainous": false, cities: ["Mobile", "New Orleans"] } ],
  [ "Mobile-Tallahassee", { "length": 2, "mountainous": false, cities: ["Mobile", "Tallahassee"] } ],
  [ "Montreal-Ottawa", { "length": 1, "mountainous": false, cities: ["Montreal", "Ottawa"] } ],
  [ "Montreal-Quebec City", { "length": 1, "mountainous": false, cities: ["Montreal", "Quebec City"] } ],
  [ "Nashville-Saint Louis", { "length": 2, "mountainous": false, cities: ["Nashville", "Saint Louis"] } ],
  [ "New York-Philadelphia", { "length": 1, "mountainous": false, cities: ["New York", "Philadelphia"] } ],
  [ "New York-Syracuse", { "length": 2, "mountainous": true, cities: ["New York", "Syracuse"] } ],
  [ "Norfolk-Raleigh", { "length": 1, "mountainous": false, cities: ["Norfolk", "Raleigh"] } ],
  [ "Norfolk-Richmond", { "length": 1, "mountainous": false, cities: ["Norfolk", "Richmond"] } ],
  [ "Omaha-Sioux City", { "length": 1, "mountainous": false, cities: ["Omaha", "Sioux City"] } ],
  [ "Ottawa-Sudbury", { "length": 3, "mountainous": false, cities: ["Ottawa", "Sudbury"] } ],
  [ "Ottawa-Syracuse", { "length": 1, "mountainous": false, cities: ["Ottawa", "Syracuse"] } ],
  [ "Ottawa-Toronto", { "length": 2, "mountainous": false, cities: ["Ottawa", "Toronto"] } ],
  [ "Philadelphia-Pittsburgh", { "length": 2, "mountainous": true, cities: ["Philadelphia", "Pittsburgh"] } ],
  [ "Philadelphia-Washington", { "length": 1, "mountainous": false, cities: ["Philadelphia", "Washington"] } ],
  [ "Phoenix-San Diego", { "length": 3, "mountainous": false, cities: ["Phoenix", "San Diego"] } ],
  [ "Pittsburgh-Washington", { "length": 2, "mountainous": true, cities: ["Pittsburgh", "Washington"] } ],
  [ "Portland ME-Quebec City", { "length": 2, "mountainous": true, cities: ["Portland ME", "Quebec City"] } ],
  [ "Portland ME-Saint John", { "length": 2, "mountainous": false, cities: ["Portland ME", "Saint John"] } ],
  [ "Portland OR-Salem", { "length": 1, "mountainous": false, cities: ["Portland OR", "Salem"] } ],
  [ "Portland OR-Seattle", { "length": 1, "mountainous": false, cities: ["Portland OR", "Seattle"] } ],
  [ "Portland OR-Spokane", { "length": 2, "mountainous": false, cities: ["Portland OR", "Spokane"] } ],
  [ "Quebec City-Saint John", { "length": 3, "mountainous": false, cities: ["Quebec City", "Saint John"] } ],
  [ "Raleigh-Richmond", { "length": 1, "mountainous": false, cities: ["Raleigh", "Richmond"] } ],
  [ "Rapid City-Sioux City", { "length": 4, "mountainous": false, cities: ["Rapid City", "Sioux City"] } ],
  [ "Redding-Reno", { "length": 1, "mountainous": true, cities: ["Redding", "Reno"] } ],
  [ "Redding-San Francisco", { "length": 2, "mountainous": false, cities: ["Redding", "San Francisco"] } ],
  [ "Regina-Saskatoon", { "length": 1, "mountainous": false, cities: ["Regina", "Saskatoon"] } ],
  [ "Regina-Winnipeg", { "length": 3, "mountainous": false, cities: ["Regina", "Winnipeg"] } ],
  [ "Reno-Salt Lake City", { "length": 4, "mountainous": true, cities: ["Reno", "Salt Lake City"] } ],
  [ "Reno-San Francisco", { "length": 2, "mountainous": true, cities: ["Reno", "San Francisco"] } ],
  [ "Richmond-Washington", { "length": 1, "mountainous": false, cities: ["Richmond", "Washington"] } ],
  [ "Seattle-Spokane", { "length": 2, "mountainous": true, cities: ["Seattle", "Spokane"] } ],
  [ "Seattle-Vancouver", { "length": 1, "mountainous": false, cities: ["Seattle", "Vancouver"] } ],
  [ "Sudbury-Thunder Bay", { "length": 4, "mountainous": false, cities: ["Sudbury", "Thunder Bay"] } ],
  [ "Sudbury-Toronto", { "length": 2, "mountainous": false, cities: ["Sudbury", "Toronto"] } ],
  [ "Tallahassee-Tampa", { "length": 2, "mountainous": false, cities: ["Tallahassee", "Tampa"] } ],
  [ "Thunder Bay-Winnipeg", { "length": 4, "mountainous": false, cities: ["Thunder Bay", "Winnipeg"] } ]
]);

/*
 * commodities
 * 
 * key:           name of commodity
 * value: {
 *  regions       [String] - regions with cities that supply the commodity
 *  cities        [String] - cities that supply the commodity
 * }
 */
export const commodities = new Map([
  [ "aluminum", { "regions": ["Eastern"], "cities": ["Portland ME"] } ],
  [ "bauxite", { "regions": ["Central"], "cities": ["Memphis"] } ],
  [ "beer", { "regions": ["Central"], "cities": ["Milwaukee", "Saint Louis"] } ],
  [ "cars", { "regions": ["Central"], "cities": ["Detroit", "Toronto"] } ],
  [ "cattle", { "regions": ["Central", "Eastern", "Western"], "cities": ["Dodge City", "Kansas City", "Miami", "Rapid City", "Thunder Bay", "Winnipeg"] } ],
  [ "coal", { "regions": ["Central", "Eastern", "Western"], "cities": ["Atlanta", "Butte", "Calgary", "Chicago", "Cincinnati", "Nashville", "Pittsburgh", "Richmond", "Saint John"] } ],
  [ "copper", { "regions": ["Western"], "cities": ["Billings", "Boise", "Calgary", "El Paso", "Flagstaff", "Phoenix"] } ],
  [ "corn", { "regions": ["Central"], "cities": ["Des Moines", "Indianapolis", "Minneapolis"] } ],
  [ "cotton", { "regions": ["Eastern"], "cities": ["Atlanta", "Charleston"] } ],
  [ "fish", { "regions": ["Central", "Western"], "cities": ["New Orleans", "Seattle", "Vancouver"] } ],
  [ "fruit", { "regions": ["Eastern", "Western"], "cities": ["Los Angeles", "San Diego", "Tampa"] } ],
  [ "imports", { "regions": ["Eastern", "Western"], "cities": ["Boston", "Los Angeles", "Montreal", "New York", "Philadelphia", "Quebec City", "San Francisco"] } ],
  [ "iron ore", { "regions": ["Central", "Western"], "cities": ["Duluth", "Grand Rapids", "Minneapolis", "Redding", "Salt Lake City", "Thunder Bay"] } ],
  [ "lead", { "regions": ["Western"], "cities": ["Billings", "Butte", "Calgary", "Regina"] } ],
  [ "machinery", { "regions": ["Central", "Eastern"], "cities": ["Boston", "Buffalo", "Chicago", "Milwaukee", "Syracuse", "Toledo"] } ],
  [ "nickel", { "regions": ["Central", "Western"], "cities": ["Regina", "Sudbury"] } ],
  [ "oats", { "regions": ["Central", "Western"], "cities": ["Omaha", "Regina", "Sioux City", "Thunder Bay"] } ],
  [ "oil", { "regions": ["Central", "Western"], "cities": ["Dallas", "Edmonton", "Houston", "Oklahoma City"] } ],
  [ "paper", { "regions": ["Central"], "cities": ["Memphis", "Saint Louis"] } ],
  [ "pork", { "regions": ["Central"], "cities": ["Fargo", "Indianapolis", "Minneapolis", "Sioux City"] } ],
  [ "potash", { "regions": ["Western"], "cities": ["Santa Fe", "Saskatoon"] } ],
  [ "precious metals", { "regions": ["Western"], "cities": ["Reno", "San Francisco", "Vancouver"] } ],
  [ "rice", { "regions": ["Central"], "cities": ["Houston", "Mobile", "New Orleans"] } ],
  [ "salt", { "regions": ["Central", "Eastern", "Western"], "cities": ["Cleveland", "Kansas City", "New Orleans", "New York", "Philadelphia", "Salt Lake City", "San Antonio", "San Diego"] } ],
  [ "sheep", { "regions": ["Western"], "cities": ["Butte", "Denver", "Salt Lake City"] } ],
  [ "steel", { "regions": ["Eastern"], "cities": ["Birmingham", "Pittsburgh"] } ],
  [ "textiles", { "regions": ["Eastern"], "cities": ["Atlanta", "Raleigh", "Savannah", "Tallahassee"] } ],
  [ "tobacco", { "regions": ["Eastern"], "cities": ["Charleston", "Norfolk", "Raleigh"] } ],
  [ "tourists", { "regions": ["Eastern"], "cities": ["New York", "Philadelphia"] } ],
  [ "wheat", { "regions": ["Central"], "cities": ["Cincinnati", "Des Moines", "Dodge City", "Kansas City", "Omaha", "Winnipeg"] } ],
  [ "wine", { "regions": ["Western"], "cities": ["Los Angeles", "San Francisco", "Spokane"] } ],
  [ "wood", { "regions": ["Eastern", "Western"], "cities": ["Ottawa", "Portland ME", "Portland OR", "Quebec City", "Salem", "Seattle", "Vancouver"] } ]
]);
